@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.cart-mobile__header {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;

  @include mainContentStyles;

  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 14px;

    &.no-items {
      padding-bottom: 0;
    }

    .title-block {
      display: flex;
      align-items: center;
      gap: 10px;

      @include min-428-break {
        gap: 12px;
      }

      .title {
        @include typography-m;
        @include weight-semi-bold;

        @include min-428-break {
          @include typography-l;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 12px;
      min-height: 34px;

      @include min-428-break {
        gap: 14px;
        min-height: 44px;
      }
    }
  }

  &-bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-top: 14px;

    @include min-428-break {
      gap: 12px;
    }
  }
}
